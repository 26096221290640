import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { useEffect, useState, useContext } from 'react'
import Cart from '../cart/cart'
import Userdata from '../cart/Userdata'
import { CheckOut } from './CheckOut'
import Steps from './Steps'
import { Link } from "gatsby" 
import PageContext from "../../context/PageContext";
import { Transport } from '../cart/Transport'
import { Payment } from '../cart/Payment'
 
const steps = [
    { name: 'Košík', href: '#', current: false },
    { name: 'Kontaktní údaje', href: '#', current: true },
    { name: 'Rekapitulace', href: '#', current: false }, 
]

export default function Wizard(props) {
    
    let step
    if(!props.step) {
        step = 1
    } else {
        step = props.step
    }
    const [context] = useContext(PageContext);
    const cart = context?.cart;
    const transportId = context?.cart?.cart?.transport
    const paymentId = context?.cart?.cart?.payment
    const transports = context?.cart?.cart?.transports
    const payments = context?.cart?.cart?.payments
    const cartItems = context?.cart?.items;
     
    const [activeTab, setActiveTab] = useState(step)
    
    useEffect(() => { 
        if(parseInt(step)===1) {
         //   props.setTitle('Nákupní košík')
        } else if(parseInt(step)===2) {
         //   props.setTitle('Kontaktní údaje')
        } else {
         //   props.setTitle('Odeslání přihlášky')
        }
        setActiveTab(parseInt(step))
      }
      // eslint-disable-next-line 
    , [step]);

  return (
      <>
     
     <div className="py-5 mt-2">
            <Steps steps={steps} activeTab={activeTab} setActiveTab={setActiveTab} />

     </div>

    <div className=" min-h-96">
     {activeTab===1 &&
     <>
        <div className="max-w-3xl mx-auto p-5">
            <Cart {...props}  />
        </div>
        <div>
            {cartItems && cartItems.length > 0 && 
                <>
                {transports && <Transport transportId={transportId} transports={transports} />}
                
                {payments && payments.length > 0 && <Payment paymentId={paymentId} payments={payments} /> }
                </>
            }
    
        {cart && cart.items?.length > 0 && 
        <div  className="max-w-3xl mx-auto">
                <div className="text-2xl text-right p-4 ">Celkem: <span className="font-medium">{parseInt(cart?.cart?.sum).toLocaleString('cs')} Kč</span></div>  
            <div className="grid justify-end">


                <div className=" p-4">
                    {transportId && paymentId ? 
                        <Link type="button" to={'/kosik/2'} className="flex items-center   text-sky-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-sky-600 rounded-md border border-gray-200 hover:bg-sky-700">
                            <span>Pokračovat</span>
                            <ChevronRightIcon className="w-8 " /> 
                        </Link>
                    :
                        <button disabled className="flex items-center   text-gray-400 h-12 opacity-50 cursor-not-allowed  px-5 py-1 focus:outline-none  space-x-4 bg-gray-200 rounded-md border border-gray-200 ">
                            <span>Pokračovat</span>
                            <ChevronRightIcon className="w-8 " /> 
                        </button>
                    }
                </div>
            </div>
            </div>
        }
        </div>

        
    </>
     }

     {activeTab===2 && 
     <>
        <div className="max-w-3xl mx-auto p-5">

            <Userdata {...props} cartItems={props.cartItems} cartId={props.cartId} setActiveTab={setActiveTab} />
        </div>
     </>
     }

    {activeTab===3 && 
     <>
        <div className="max-w-3xl mx-auto p-5">

           
            <CheckOut {...props} />
        </div>
     </>
     }

    </div>
     </>
  )
}