
import React, { useState, useEffect, useContext} from "react";
import { useForm } from "react-hook-form";   
//import { AuthContext } from "../../context/context";
import {  
  ChevronRightIcon, InformationCircleIcon
} from '@heroicons/react/solid' 
import axiosInstance from "../../client/index.js"; 
import { Loading } from "../layout/Loading.js";
import Cart from "../cart/cart"; 
import {Error } from '../form/Error' 
import PageContext from "../../context/PageContext";
import { navigate } from "gatsby-link";
import { Transport } from '../cart/Transport'
import { Payment } from '../cart/Payment'
import { Terms } from "../elements/Terms.js";

export const CheckOut = (props) => {
 //   const authContext = useContext(AuthContext);
    const [context, dispatch] = useContext(PageContext);
    const cart = context?.cart
    
    const transportId = context?.cart?.cart?.transport
    const paymentId = context?.cart?.cart?.payment
    const transports = context?.cart?.cart?.transports
    const payments = context?.cart?.cart?.payments
 
 
    const [loading, setLoading] = useState(false);    
    const userData = JSON.parse(localStorage.getItem('userDataStatus'));
    const address = userData?.data?.data?.transport
     
    const { register, handleSubmit, formState: { errors }  } = useForm({
      mode: "onBlur"
    });  
    
    const onSubmit = async data => { 
      setLoading(true);
      try {
        const res = await axiosInstance.post('wizard/cartSend/', 
        {
          cartId: cart?.cart?.cartHash,
          data: userData?.data?.data,
          notes: data
        })
        if(res.data) { 
         localStorage.removeItem('userDataStatus'); 
         let cartHash
         cartHash = 'cart_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
         localStorage.setItem("cartId", cartHash);
         dispatch({
            type: 'SET_ITEMS',
            data: {}
         }) 
         navigate('/platba/' + res.data.order_number + '/')
        }
      } catch(err) {
          console.log(err);
          setLoading(false); 
      }
    }; 

    useEffect(() => { 
       setLoading(false);
       cart && (!cart.items || cart.items.length === 0) &&  navigate('/')
    }
    // eslint-disable-next-line 
  , []);
 
    function infoModal() { 
        dispatch({
           type: 'SET_MODAL',
           payload: {
             component: Terms,
           }
        }) 
         
    }
      
    return (
       
            <> 
            {cart.items && cart?.items?.length > 0 
            && <>
            
              <div className="grid  lg:gap-2">

                <div>
                    <div className="bg-gray-100 text-gray-600 border border-gray-300 rounded-md p-5 max-w-sm">
                        <p className="font-bold">{address?.fname} {address?.surname}</p>
                        <p>{address?.street}</p>
                        <p>{address?.zip} {address?.city}</p>
                        
                        <p className="mt-5">{address?.tel}</p>
                        <p>{userData?.data?.email}</p>
                    </div>
                </div>
                <div>
                  <Cart {...props} summary={true} />

                </div>
              </div>
              <div className="grid lg:grid-cols-2 lg:gap-4 -mx-5">

                  <Transport transportId={transportId} transports={transports} summary={true} />
                  
                  <Payment paymentId={paymentId} payments={payments} summary={true} />

              </div>

            {cart && cart.items?.length > 0 &&
                <div className="text-2xl text-right py-4 ">Celkem: <span className="font-medium">{parseInt(cart?.cart?.sum).toLocaleString('cs')} Kč</span></div>  
            }
            {userData &&
            
             <form key={1} onSubmit={handleSubmit(onSubmit)}  className={"space-y-8  py-2"  } > 
                 <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Poznámka
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        id="note"
                        name="note"
                        rows={3}
                        className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                        defaultValue={''}
                      /> 
                    </div>
                  </div>
                  
                    <div className="grid sm:grid-cols-3 sm:gap-4">
                      <div className=" justify-start pb-3 lg:pb-0 ml-10 sm:ml-0">
                      <button type="button" onClick={() => infoModal()} className=" inline-flex  bg-gray-100 border border-gray-300 rounded-md shadow-sm px-4 py-1  justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                              <InformationCircleIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                              Zobrazit podmínky
                              
                            </button>
                      </div>
                      <div className="max-w-lg sm:col-span-2">
                        <div className="flex justify-end">
                         

                        </div>
                        <div className="flex sm:justify-end p-3 sm:p-0 pb-0">
                          <div className="flex items-center h-5 ">
                          

                            <input 
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-600 rounded"
                              {...register("souhlas", { required: true })}
                            />
                        
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="comments" >
                              <span className="font-medium text-gray-700">Souhlasím s obchodními podmínkami</span>
                            </label>
                           
                        {errors.souhlas && 
                            <Error text="Potvrďte prosím souhlas" />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                   
               
                    <div className="sm:col-span-2 mt-3 flex justify-end">   
                      <button   className="flex items-center w-full sm:w-auto justify-center  text-green-100 h-12 text-lg font-medium  px-5 py-1 focus:outline-none  space-x-4 bg-green-600 rounded-md border border-gray-200 hover:bg-green-700">
                        <span>Odeslat objednávku</span>
                        <ChevronRightIcon className="w-8 " /> 
                      </button> 
                    </div>
              
                  
              </form>  
            }
           </>
           } 
          {loading && 
            <>
          


              <div className="p-10 w-full h-full z-50 inset-10 absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
                   
                </div>
                <Loading loading={loading} />
            </>
          }
        </>
       
    )
}
 