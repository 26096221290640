import React, { useContext, useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import PageContext from '../../context/PageContext'
import axiosInstance from '../../client'
import { RiTruckFill } from 'react-icons/ri'
 

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Transport = ({transportId, transports, summary}) => {
  const [selected, setSelected] = useState(transportId)
 
  const [context, dispatch] = useContext(PageContext);
  const cart = context?.cart;
  const cartId = cart?.cart?.cartHash;

//   const transportId = context?.cart?.cart?.transport
//   const paymentId = context?.cart?.cart?.payment


  useEffect(() => { 
     transportId && setSelected(transportId);
  }
  // eslint-disable-next-line 
  , [transportId]);

  function setTransport(e) {
      setSelected(e);
      axiosInstance.get('cart/transport_update/' + cartId + '/?transport=' + e)
      .then(res => {
          dispatch({type:'SET_ITEMS', data:res.data}) 
      })
      .catch(function (error) {
          console.log(error); 
      });

  }

  return (
    <>
    <div className={"p-5 transition-all ease-in-out duration-300  " + (selected ? " my-2 py-2" : "bg-sky-100 my-6 ")}>

    <RadioGroup value={selected} onChange={setTransport} className="max-w-3xl mx-auto"  >
      <RadioGroup.Label className="sr-only">Doprava</RadioGroup.Label>
        <div className="flex items-center w-full justify-between py-2">
        {selected ?
          <>
            <div>Způsob dopravy</div>
            {!summary && <button onClick={() => setTransport(null)}>Změnit</button>}
          </>
            :
          <>
             <div className="flex space-x-4 items-center font-bold text-sky-800"><RiTruckFill className="w-6 h-6  " /><span>Zvolte způsob dopravy</span></div> 
          </>
          }
        </div>
      <div className="relative bg-white rounded-md -space-y-px">
        {transports && transports.length > 0 && transports.map((tran, tranIdx) => (
          <RadioGroup.Option
          key={tran.transport_id}
          value={parseInt(tran.transport_id)}
          className={({ checked }) =>
          classNames(
            tranIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
            !summary && "cursor-pointer",
            tranIdx === transports.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
            checked ? 'bg-gray-50 border-gray-200 z-10 rounded-md' : !selected ? 'border-gray-200  ' : 'hidden' ,
            'relative border p-4 flex-col md:pl-4 md:pr-6 grid  grid-cols-3  focus:outline-none hover:bg-gray-100 transition-all ease-in-out duration-300 '
            )
          }
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center text-sm col-span-2">
                  <span
                    className={classNames(
                      checked ? 'bg-sky-700 border-transparent' : 'bg-white border-gray-300',
                      summary && 'hidden',
                      active ? 'ring-2 ring-offset-2 ring-sky-700' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                      >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <RadioGroup.Label
                    as="span"
                    className={classNames(checked ? 'text-gray-900' : 'text-gray-900', 'ml-3 font-medium')}
                    >
                    {tran.transport_name}
                  </RadioGroup.Label>
                </div>
                <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 text-right">
                  <span className={classNames(checked ? 'text-gray-900' : 'text-gray-900', 'font-medium')}>
                    {tran.transport_price_vat.toLocaleString()} Kč
                  </span>{' '} 
                </RadioGroup.Description>
                 
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
    </div>
    </>
  )
}