import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ModalLayout } from "../layout/modal";


export const Terms = () => {
    const data = useStaticQuery(graphql`
    query Terms {
        scomos {
            podminky: pageFindOneByUrl(url: "vseobecne-obchodni-podminky/") {
                content
            }
        }
    }
  `)
  
   const content = data.scomos.podminky.content;

    return (
        <>
        <ModalLayout title={'Obchodní podmínky'}>
            <div className="prose prose-sm max-w-max"  dangerouslySetInnerHTML={{ __html: content }} />    

        </ModalLayout>
        </>
    )
}