 
import React from 'react';
import { XIcon } from "@heroicons/react/outline"; 
import { BsPercent } from 'react-icons/bs';
import { Link } from 'gatsby';

function CartItemslist({cartItems,deleteItem,updateItem,lastCartItem,summary}) {
    
    
    return(
        <div className="divide-y "> 
        
        { cartItems && cartItems.length > 0 &&  
            <>  
                {cartItems.map(item => (
                <div key={item.id} className={"grid grid-cols-4 lg:grid-cols-7 w-full text-gray-800 items-center border-t  pin-r pin-y  pt-3 px-3 pb-4 " + (lastCartItem && lastCartItem === item?.itemId ? "bg-blue-600 bg-opacity-10 " : "")} cellSpacing="0">
                    <Link className="  w-16 h-16 flex items-center justify-center" to={'/plysaci/detail/' + item.alias + '/'}>
                        {item.type !== 'cart_promo' && item?.img 
                        ?
                        <img
                        src={item?.img}
                        alt={item?.name}
                        className="w-full h-full object-center object-cover sm:rounded-lg"
                        />
                        : item.type === 'cart_promo' && <div className="bg-green-600 rounded-full mx-auto p-2"><BsPercent className="w-6 h-6 text-white" /></div>
                        }
                    </Link>
                    
                    <div className={"flex space-x-2  lg:space-x-0 justify-between " + (item.type !== 'cart_promo' && item.type !== 'eorder' ? summary ? "col-span-3 lg:col-span-4 " : "col-span-2 lg:col-span-3" : 
                    summary ? "col-span-2 lg:col-span-4 " : "col-span-2 lg:col-span-4")} >
                        <div className="truncate grid items-center">
                            {item.alias ? <Link to={'/plysaci/detail/' + item.alias + '/'} className="font-medium truncate">{item.name}</Link> : <><span className="font-medium truncate">{item.name}</span></>}
  
                            <div className="text-xs text-gray-500 grid sm:flex sm:space-x-6">
                                {item.quantity > 0 ? <span className=" text-green-600 font-bold">{item.quantity===1 && "Poslední kus"} skladem</span> : item.note && <span className="text-gray-600">Dostupné {item.note}</span>}
                                {item.type !== 'cart_promo' && <p >  <span>{parseFloat(item.price).toLocaleString('cs')} Kč</span> </p>}

                            </div>
                        </div>
                        
                        
                     
                    </div> 
                    {!summary && item.type !== 'cart_promo' && item.type !== 'eorder' &&
                    <div className="  lg:col-span-1 flex flex-row items-center lg:justify-center text-gray-400 ">
                        {item.type !== 'cart_promo' && item.type !== 'eorder' &&
                            <>
                            <button type="button" onClick={() => updateItem(item.id,parseInt(item.count)-1)} className="focus:outline-none hover:text-gray-900">
                                <svg className="h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                            <span className="inline-block text-center font-medium w-8  text-gray-800 ">{item.count}</span>
                            <button type="button" onClick={() => updateItem(item.id,parseInt(item.count)+1)} className="focus:outline-none hover:text-gray-900">
                                <svg  className="h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                            </>
                        }
                        </div> 
                        }
                        <div className="col-span-4 lg:col-span-2 flex justify-end items-center space-x-5 ">
                            <div className="grid">
                                {parseFloat(item.price*item.count).toLocaleString('cs')} Kč
                                
                            </div>
                            {!summary && 
                            <div className=" justify-end items-center lg:flex" >
                                <button type="button" onClick={() => deleteItem(item.id)} className="text-gray-400 hover:text-gray-900 focus:outline-none py-3">
                                    <XIcon className="w-6 text-gray-500"  />
                                </button>
                            </div>
                             }
                        </div> 
                   
                </div>
                ))}
            </>
       
       
        
        } 
        <div />
    </div>
    )

}

export default CartItemslist;